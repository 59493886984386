import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from "@angular/http";
import { BehaviorSubject } from "rxjs";
import { JsonApiService } from "@app/core/services/json-api.service";
import { Observable } from "rxjs/Observable";
import { SubscriptionNewModel } from "@app/models/subscriptionnew.model"
import { Config } from "@app/config/config";

const config = new Config();

const defaultUser = {
  username: "Admin"
}
@Injectable()
export class SubscriptionService {
  readonly rootUrl = config.webAPIUrl+'/api';

  public user$ = new BehaviorSubject({...defaultUser});

  constructor(private jsonApiService: JsonApiService, private http: HttpClient) {
    //this.jsonApiService.fetch("/user/login-info.json").subscribe(this.user$)
  }

  cancelSubscription(subscriptionId: number): Observable<any> {
    var data = "subscriptionId=" + subscriptionId;
    return this.http.post(this.rootUrl + '/Subscription/cancel?'+data, {})
  }

  createSubscription(data: SubscriptionNewModel){
    return this.http.post(this.rootUrl + '/Subscription/Create', data,{});
   }
}
