import { Injectable, Directive, OnInit, Input, ElementRef } from '@angular/core';
import { Config } from "@app/config/config";

const config = new Config();
@Injectable()

export class AuthorizationProvider{
    readonly rootUrl = config.webAPIUrl;

    constructor() {
    }

     isURLAuthorized(url: string) {
        if (localStorage.getItem(this.rootUrl + 'goldPro_userAccessMap') != null) {
            url = url.replace("//", "/").toLowerCase();
            var accessMapByUrlString = localStorage.getItem(this.rootUrl + 'goldPro_userAccessMap');
            var accessMapByUrl = JSON.parse(accessMapByUrlString);

            if(accessMapByUrl == null)
            {
                return false;
            }
            
            for(; url != ''; url = url.substr(0, url.lastIndexOf("/")))
            {
                if(accessMapByUrl[url] == null)
                {
                    continue;
                }

                break;
            }

            if(url == null || url == '')
            {
                return true;
            }

            
            for (let i = 0; i < accessMapByUrl[url].length; i++) {
                return accessMapByUrl[url][i].IsAccessible;
            }
        }
        return false;
    }
}
