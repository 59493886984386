export class EditMarkupModel {
    Id: number;
    SymbolId: number;
    BidAsk: string;
    Name: string;
    Description: string;
    Variable1: number;
    Variable2: number;
    Premium: number;
    Factor: number;
    Discount: number;
    TradingSymbolId: number;
    IsTradingSymbolIdRequired:boolean;
}