import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from "@angular/http";
import { BehaviorSubject } from "rxjs";
import { JsonApiService } from "@app/core/services/json-api.service";
import { map } from 'rxjs/operators';
import { Config } from "@app/config/config";
import { OrderCommentModel } from "@app/models/order/comment.model";
import { Observable } from "rxjs/Observable";
import { OrderDetailsModel } from "@app/models/order/orderdetails.model";

const config = new Config();

@Injectable()
export class OrderService {
    readonly rootUrl = config.webAPIUrl + '/api';

    constructor(private jsonApiService: JsonApiService, private http: HttpClient) {
    }
  
     getOrderMetalStatusList() {
         return this.http.get(this.rootUrl+"/order/metalorderstatus");
     }

     getOrderStatusList() {
        return this.http.get(this.rootUrl+"/order/orderstatus");
    }

    editComment(orderCommentModel: OrderCommentModel): Observable<object> {
        return this.http.post(this.rootUrl + '/order/editcomment', orderCommentModel, {});
    }

    getComment(orderId: number): Observable<object> {
        return this.http.get(this.rootUrl + '/order/getcomment/'+orderId, {});
    }

    getDetails(orderId: number): Observable<object> {
        return this.http.get(this.rootUrl + '/order/getdetails/'+orderId, {});
    }
  }
  