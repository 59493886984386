import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from "@angular/http";
import { BehaviorSubject } from "rxjs";
import { JsonApiService } from "@app/core/services/json-api.service";
import { map } from 'rxjs/operators';
import { Config } from "@app/config/config";

const config = new Config();

@Injectable()
export class OrderUnitService {
    readonly rootUrl = config.webAPIUrl + '/api';

    constructor(private jsonApiService: JsonApiService, private http: HttpClient) {
    }
  
     getList() {
         return this.http.get(this.rootUrl+"/orderunit/list");
     }
  }
  