import { Injectable, Directive, OnInit, Input, ElementRef } from '@angular/core';
import { Config } from "@app/config/config";
import { AuthorizationProvider } from './authorizationprovider';

const config = new Config();

@Directive({
    selector: '[HideIfUnauthorized]'
})
export class AuthorizationDirective implements OnInit{
    readonly rootUrl = config.webAPIUrl;
    @Input('HideIfUnauthorized') permissionURL: string;

    constructor(private el: ElementRef, private authorizationProvider: AuthorizationProvider) {
    }

    ngOnInit()
    {
        if(!this.authorizationProvider.isURLAuthorized(this.permissionURL))
        {
            this.el.nativeElement.style.display = 'none';
        }
    }
}
