import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JsonApiService } from "@app/core/services/json-api.service";
import { Observable } from "rxjs/Observable";
import { IResponseMessage } from "@app/models/responsemessage.model";
import { Config } from "@app/config/config";
import { CreateProductModel } from "@app/models/product/createproduct.model";
import { EditProductModel } from "@app/models/product/editproduct.model";

const config = new Config();

@Injectable()
export class ProductService {
    readonly rootUrl = config.webAPIUrl + '/api';;

    constructor(private jsonApiService: JsonApiService, private http: HttpClient) {
    }
    
    create(product: CreateProductModel): Observable<object> {
        return this.http.post(this.rootUrl + '/product/create', product, {});
    }

    get(productId: number): Observable<IResponseMessage> {
        return this.http.get<IResponseMessage>(this.rootUrl + '/product/get/' + productId);
    }

    edit(product: EditProductModel): Observable<object> {
        return this.http.post(this.rootUrl + '/product/edit', product, {});
    }

    delete(productId: number): Observable<object> {
        return this.http.get<IResponseMessage>(this.rootUrl + '/product/delete/' + productId);
    }

    getDisplayedProducts(): Observable<object> {
        return this.http.get<IResponseMessage>(this.rootUrl + '/product/get/display/');
    }
}
