import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import * as fromAuth from '../store/auth';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Config } from "@app/config/config";
import { UserService } from '../../core/services/user.service';
import { AuthorizationProvider } from './authorizationprovider';

const config = new Config();

@Injectable()
export class AuthGuard implements CanActivate {
  readonly rootUrl = config.webAPIUrl;

  constructor(private router: Router, private userService: UserService, private authorizationProvider: AuthorizationProvider) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (localStorage.getItem(this.rootUrl + 'goldPro_userToken') != null) {
      if(!this.authorizationProvider.isURLAuthorized(state.url))
      {
        this.router.navigate(['unauthorized']);
      }
      return true;
    }
    
    this.router.navigate(['auth/login']);
    return false;
  }
}
