import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from "@angular/http";
import { BehaviorSubject } from "rxjs";
import { JsonApiService } from "@app/core/services/json-api.service";
import { Observable } from "rxjs/Observable";
import { map } from 'rxjs/operators';
import { IResponseMessage } from "@app/models/responsemessage.model";
import { Config } from "@app/config/config";
import { CreateMemberGroupModel } from "@app/models/membergroup/createmembergroup.model";
import { EditMemberGroupModel } from "@app/models/membergroup/editmembergroup.model";

const config = new Config();

@Injectable()
export class MemberGroupService {
    readonly rootUrl = config.webAPIUrl + '/api';;

    constructor(private jsonApiService: JsonApiService, private http: HttpClient) {
    }
  
     getMemberGroups() {
         return this.http.get(this.rootUrl+"/membergroup/list");
     }

     create(memberGroup: CreateMemberGroupModel): Observable<object> {
        return this.http.post(this.rootUrl + '/membergroup/create', memberGroup, {});
    }

    get(memberGroupId: number): Observable<IResponseMessage> {
        return this.http.get<IResponseMessage>(this.rootUrl + '/membergroup/get/' + memberGroupId);
    }

    edit(markup: EditMemberGroupModel): Observable<object> {
        return this.http.post(this.rootUrl + '/membergroup/edit', markup, {});
    }

    delete(markupId: number): Observable<object> {
        return this.http.get<IResponseMessage>(this.rootUrl + '/membergroup/delete/' + markupId);
    }
  }
  