export class OrderDetailsModel {
    TransactionId: number;
    TransactionDate: any;
    ProductName: string;
    PricePerUnitInUSD: number;
    PricePerOzInUSD: number;
    PricePerOzInSGD: number;
    TotalPriceInUSDBeforeMarkup: number;
    TotalPriceInSGDBeforeMarkup: number;
    TotalPriceInUSDAfterMarkup: number;
    TotalPriceInSGDAfterMarkup: number;
    Quantity: number;
    QuantityInOz: number;
    FIXAPIOrderQuantity: number;
    ExchangeRate: number;
    Book: string;
    Residue: number;
    FIXAPIOrderId: string;
    FIXAPIExecutionId: string;
    FIXAPIExecutionType: string;
    FIXAPIOrderStatus: string;
}
