import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JsonApiService } from "@app/core/services/json-api.service";
import { Observable } from "rxjs/Observable";
import { IResponseMessage } from "@app/models/responsemessage.model";
import { Config } from "@app/config/config";
import { CreateMemberModel } from "@app/models/member/createmember.model";
import { EditMemberModel } from "@app/models/member/editmember.model";
import { EditMemberBuySellLimitModel } from '@app/models/member/editbuyselllimit.model';
import { MemberBuySellLimitModel } from '@app/models/member/buyselllimit.model';

const config = new Config();
@Injectable()
export class MemberService {
    readonly rootUrl = config.webAPIUrl + '/api';

    constructor(private jsonApiService: JsonApiService, private http: HttpClient) {
    }

    create(markup: CreateMemberModel): Observable<object> {
        return this.http.post(this.rootUrl + '/member/create', markup, {});
    }

    enable(memberId: number): Observable<object> {
        return this.http.get<IResponseMessage>(this.rootUrl + '/member/enable/' + memberId);
    }

    disable(memberId: number): Observable<object> {
        return this.http.get<IResponseMessage>(this.rootUrl + '/member/disable/' + memberId);
    }

    delete(memberId: number): Observable<object> {
        return this.http.get<IResponseMessage>(this.rootUrl + '/member/delete/' + memberId);
    }

    get(memberId: number): Observable<IResponseMessage> {
        return this.http.get<IResponseMessage>(this.rootUrl + '/member/get/' + memberId);
    }

    edit(member: EditMemberModel): Observable<object> {
        return this.http.post(this.rootUrl + '/member/edit', member, {});
    }

    editBuySellLimit(member: EditMemberBuySellLimitModel): Observable<object> {
        return this.http.post(this.rootUrl + '/member/editbuyselllimit', member, {});
    }

    getBuySellLimit(memberId: number): Observable<IResponseMessage> {
        return this.http.get<IResponseMessage>(this.rootUrl + '/member/memberbuyselllimit/' + memberId);
    }
}
