import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JsonApiService } from "@app/core/services/json-api.service";
import { Observable } from "rxjs/Observable";
import { IResponseMessage } from "@app/models/responsemessage.model";
import { Config } from "@app/config/config";

const config = new Config();

@Injectable()
export class ResidueService {
    readonly rootUrl = config.webAPIUrl + '/api';;

    constructor(private jsonApiService: JsonApiService, private http: HttpClient) {
    }
    
    getUnfillResidueBalance(): Observable<IResponseMessage> {
        return this.http.get<IResponseMessage>(this.rootUrl + '/residue/unfillresiduebalance');
    }
}
