import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from "@angular/http";
import { BehaviorSubject } from "rxjs";
import { JsonApiService } from "@app/core/services/json-api.service";
import { Observable } from "rxjs/Observable";
import { map } from 'rxjs/operators';
import { Config } from "@app/config/config";
import { IResponseMessage } from "@app/models/responsemessage.model";
import { CreateBookSchedulerModel } from "@app/models/bookscheduler/createbookscheduler.model";
import { EditBookSchedulerModel } from "@app/models/bookscheduler/editbookscheduler.model";

const config = new Config();

@Injectable()
export class BookSchedulerService {
  readonly rootUrl = config.webAPIUrl + '/api';

  constructor(private jsonApiService: JsonApiService, private http: HttpClient) {
  }

  delete(scheduleId: number): Observable<object> {
    return this.http.get<IResponseMessage>(this.rootUrl + '/bookscheduler/delete/' + scheduleId);
  }

  enable(scheduleId: number): Observable<object> {
    return this.http.get<IResponseMessage>(this.rootUrl + '/bookscheduler/enable/' + scheduleId);
  }

  disable(scheduleId: number): Observable<object> {
    return this.http.get<IResponseMessage>(this.rootUrl + '/bookscheduler/disable/' + scheduleId);
  }

  create(schedule: CreateBookSchedulerModel): Observable<object> {
    return this.http.post(this.rootUrl + '/bookscheduler/create', schedule, {});
  }

  get(scheduleId: number): Observable<IResponseMessage> {
    return this.http.get<IResponseMessage>(this.rootUrl + '/bookscheduler/get/' + scheduleId);
  }

  edit(schedule: EditBookSchedulerModel): Observable<object> {
    return this.http.post(this.rootUrl + '/bookscheduler/edit', schedule, {});
  }
}
