import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from "@angular/http";
import { BehaviorSubject } from "rxjs";
import { JsonApiService } from "@app/core/services/json-api.service";
import { map } from 'rxjs/operators';
import { Config } from "@app/config/config";
import { OrderCommentModel } from "@app/models/order/comment.model";
import { Observable } from "rxjs/Observable";
import { CreateOrderDeliveryModel } from "@app/models/order/createorderdelivery.model";

const config = new Config();

@Injectable()
export class OrderDeliveryService {
    readonly rootUrl = config.webAPIUrl + '/api';

    constructor(private jsonApiService: JsonApiService, private http: HttpClient) {
    }

    create(formData: FormData): Observable<object> {
        return this.http.post(this.rootUrl + '/orderdelivery/create', formData, {});
    }

    edit(formData: FormData): Observable<object> {
        return this.http.post(this.rootUrl + '/orderdelivery/edit', formData, {});
    }

    get(orderDeliveryId: number): Observable<object> {
        return this.http.get(this.rootUrl+"/orderdelivery/get/"+orderDeliveryId);
    }

    getList(orderId: number): Observable<object> {
        return this.http.get(this.rootUrl+"/orderdelivery/list/"+orderId);
    }

    getInvoice(fileId: number): Observable<object> {
        return this.http.get(this.rootUrl+"/orderdelivery/invoice/"+fileId, {responseType: 'blob'});
    }
  }
  