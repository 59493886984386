import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from "@angular/http";
import { BehaviorSubject } from "rxjs";
import { JsonApiService } from "@app/core/services/json-api.service";
import { CreateMt4AccountModel } from '@app/models/createmt4account.model';
import { SubscriptionPackage } from '@app/models/subscriptionpackage.model';
import { IResponseMessage } from "@app/models/responsemessage.model";
import { Observable } from "rxjs/Observable";
import { Config } from "@app/config/config";
import { ChangePasswordModel } from "@app/models/changepassword.model";

const config = new Config();
const defaultUser = {
  username: "Admin"
}
@Injectable()
export class UserService {
  readonly rootUrl = config.webAPIUrl;

  public user$ = new BehaviorSubject({...defaultUser});

  constructor(private jsonApiService: JsonApiService, private http: HttpClient) {
    //this.jsonApiService.fetch("/user/login-info.json").subscribe(this.user$)
  }

  public logout(){
    localStorage.removeItem(this.rootUrl+'goldPro_userToken');
    this.user$.next({...defaultUser})
  }

  userAuthentication(userName, password) {
    var data = "username=" + userName + "&password=" + password + "&grant_type=password&login_type=password&access_portal=" + config.accessPortal;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });

    return this.http.post(this.rootUrl + '/token', data, { headers: reqHeader });
  }

  getUserClaims(){
   return  this.http.get(this.rootUrl+'/api/authentication/GetUserClaims');
  }

   resetPassword(username: string): Observable<object> {
    var data = "username=" + username;
    return this.http.get<IResponseMessage>(this.rootUrl + '/api/user/adminforgotpassword?'+data);
  }

  changePassword(model: ChangePasswordModel): Observable<object> {
    return this.http.post(this.rootUrl + '/api/user/changepassword', model, {});
}
}
