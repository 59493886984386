import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { NotificationService } from '@app/core/services';
import { SymbolService } from '@app/core/services';
import { ModalDirective } from "ngx-bootstrap";;
import { Config } from "@app/config/config";
import { OrderDetailsModel } from "@app/models/order/orderdetails.model";
import { OrderService } from '@app/core/services/order.service';
import { DatePipe } from '@angular/common';
import { DatatableComponent } from '@app/shared/ui/datatable/datatable.component';

const config = new Config();
@Component({
  selector: 'tradeexecution-orderdetails',
  templateUrl: './orderdetails.component.html',
})
export class OrderDetailsComponent implements OnInit {
  public loading = false;
  public REST_ROOT = config.webAPIUrl + '/api';
  public model = new OrderDetailsModel();
  constructor(private http: HttpClient, private notificationService: NotificationService,
    private orderService: OrderService, private datePipe: DatePipe) { }

  _orderId: number;
  _modal: any;
  _tradeList: any;

  ngOnInit() {

  }

  initialize(orderId: number, modal: ModalDirective, tradeList: DatatableComponent) {
    this._modal = modal;
    this._tradeList = tradeList;
    this._orderId = orderId;

    this.loading = true;
    this.orderService.getDetails(this._orderId)
      .subscribe((data: any) => {
        this.loading = false;
        if (data.Success) {
          var orderDetails = data.Data as OrderDetailsModel;
          this.model.TransactionId = orderDetails.TransactionId;
          this.model.TransactionDate = orderDetails.TransactionDate;
          this.model.ProductName = orderDetails.ProductName;
          this.model.PricePerUnitInUSD = orderDetails.PricePerUnitInUSD;
          this.model.PricePerOzInUSD = orderDetails.PricePerOzInUSD;
          this.model.PricePerOzInSGD = orderDetails.PricePerOzInSGD;
          this.model.TotalPriceInUSDBeforeMarkup = orderDetails.TotalPriceInUSDBeforeMarkup;
          this.model.TotalPriceInSGDBeforeMarkup = orderDetails.TotalPriceInSGDBeforeMarkup;
          this.model.TotalPriceInUSDAfterMarkup = orderDetails.TotalPriceInUSDAfterMarkup;
          this.model.TotalPriceInSGDAfterMarkup = orderDetails.TotalPriceInSGDAfterMarkup;
          this.model.Quantity = orderDetails.Quantity;
          this.model.QuantityInOz = orderDetails.QuantityInOz;
          this.model.FIXAPIOrderQuantity = orderDetails.FIXAPIOrderQuantity;
          this.model.ExchangeRate = orderDetails.ExchangeRate;
          this.model.Book = orderDetails.Book;
          this.model.Residue = orderDetails.Residue;
          this.model.FIXAPIOrderId = orderDetails.FIXAPIOrderId;
          this.model.FIXAPIExecutionId = orderDetails.FIXAPIExecutionId;
          this.model.FIXAPIExecutionType = orderDetails.FIXAPIExecutionType;
          this.model.FIXAPIOrderStatus = orderDetails.FIXAPIOrderStatus;
        }
      });
  }


  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }
}
