import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from "@angular/http";
import { BehaviorSubject } from "rxjs";
import { JsonApiService } from "@app/core/services/json-api.service";
import { Observable } from "rxjs/Observable";
import { MarkupModel } from "@app/models/markup/markup.model";
import { CreateMarkupModel } from "@app/models/markup/createmarkup.model";
import { EditMarkupModel } from "@app/models/markup/editmarkup.model";
import { IResponseMessage } from "@app/models/responsemessage.model";
import { Config } from "@app/config/config";

const config = new Config();
@Injectable()
export class MarkupService {
    readonly rootUrl = config.webAPIUrl + '/api';

    constructor(private jsonApiService: JsonApiService, private http: HttpClient) {
    }

    get(markupId: number): Observable<IResponseMessage> {
        return this.http.get<IResponseMessage>(this.rootUrl + '/markup/get/' + markupId);
    }

    create(markup: CreateMarkupModel): Observable<object> {
        return this.http.post(this.rootUrl + '/markup/create', markup, {});
    }

    edit(markup: EditMarkupModel): Observable<object> {
        return this.http.post(this.rootUrl + '/markup/edit', markup, {});
    }

    delete(markupId: number): Observable<object> {
        return this.http.get<IResponseMessage>(this.rootUrl + '/markup/delete/' + markupId);
    }

    getList() {
        return this.http.get(this.rootUrl+"/markup/getall");
    }

    getListBySymbol(symbolId: number) {
        return this.http.get(this.rootUrl+'/markup/getbysymbol/' + symbolId);
    }

}
