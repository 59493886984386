export class MemberDetailsModel {
    MemberId: number;
    Name: string;
    Email: string;
    ReferenceNumber: string;
    ContactPerson: string;
    ContactPersonDesignation: string;
    ContactNumber: string;
    MemberGroupId: number;
    MemberGroup: string;
    MemberStatusId: number;
    IsDepositRequired: boolean;
    DepositReceived: number;
    UserId: number;
    AddressId: number;
    Address1: string;
    Address2: string;
    City: string;
    State: string;
    PostalCode: string;
    CountryId: number;
    Country: string;
    OverrideBuySellLimit: boolean;
    BuyLimit: number;
    SellLimit: number;
}