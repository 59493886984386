import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NotificationService } from '@app/core/services';
import { CountryService } from '@app/core/services/country.service';
import { AdminUserService } from '@app/core/services/adminuser.service';
import { ModalDirective } from "ngx-bootstrap";;
import { DatatableComponent } from '@app/shared/ui/datatable/datatable.component';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { EditMarkupModel } from '@app/models/markup/editmarkup.model';
import { SymbolService } from '@app/core/services/symbol.service';
import { MarkupService } from '@app/core/services/markup.service';
import { SymbolPricingModel } from "@app/models/symbol/symbolpricing.model";
import { MemberGroups } from '@app/models/membergroup/membergroup.model';
import { MemberGroupService } from '@app/core/services/membergroup.service';

@Component({
    selector: 'markup-view',
    templateUrl: './view.component.html',
})
export class MarkupViewComponent implements OnInit {
    public loading = false;
    memberGroups: MemberGroups[];
    symbolPricing: SymbolPricingModel[];
    submitted = false;

    public model = new EditMarkupModel();

    constructor(private notificationService: NotificationService, private route: ActivatedRoute, private memberGroupService: MemberGroupService, private datePipe: DatePipe
        , private formBuilder: FormBuilder, private symbolService: SymbolService, private markupService: MarkupService) { };

    _markupId: number;
    _modal: any;
    _markupList: any;

    ngOnInit() {

    }


    ngAfterViewInit() {
    }

    initialize(markupId: number, modal: ModalDirective, markupList: DatatableComponent) {
        this._modal = modal;
        this._markupList = markupList;

        //get member group list
        var memberGroupsList = [];
        this.memberGroupService.getMemberGroups()
            .subscribe((data: any) => {
                if (data.Success) {
                    this.memberGroups = data.Data as MemberGroups[];
                    $.each(data.Data, function (index, value) {
                        memberGroupsList.push({ "id": data.Data[index].Id, "text": data.Data[index].Name });
                    });

                    $("#memberGroupSelect2Edit").select2({
                        data: memberGroupsList
                    })

                    this._markupId = markupId;
                    this.markupService.get(this._markupId).subscribe(
                        data => {
                            console.log(data);
                            if (data.Success) {
                                this.model.Id = +data.Data.Id;
                                this.model.SymbolId = +data.Data.SymbolId;
                                this.model.BidAsk = data.Data.BidAsk;
                                this.model.Name = data.Data.Name;
                                this.model.Description = data.Data.Description;
                                this.model.Variable1 = data.Data.Variable1;
                                this.model.Variable2 = +data.Data.Variable2;
                                this.model.Premium = +data.Data.Premium;
                                this.model.Factor = +data.Data.Factor;
                                this.model.Discount = +data.Data.Discount;
                                // this.model.MemberGroupIds = null;

                                // if (data.Data.MemberGroupIds.length > 0) {
                                //     this.model.MemberGroupIds = [];
                                //     this.model.MemberGroupIds = data.Data.MemberGroupIds;
                                // }

                                // $("#memberGroupSelect2Edit").val(this.model.MemberGroupIds).trigger('change');

                            }

                        },
                        err => console.error(err)
                    );
                }
            });
    }

    private handleError(error: any) {
        // In a real world app, we might use a remote logging infrastructure
        // We'd also dig deeper into the error to get a better message
        let errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        console.error(errMsg); // log to console instead
        return Observable.throw(errMsg);
    }
}