import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from "@angular/http";
import { BehaviorSubject } from "rxjs";
import { JsonApiService } from "@app/core/services/json-api.service";
import { Observable } from "rxjs/Observable";
import { map } from 'rxjs/operators';
import { Config } from "@app/config/config";
import { IResponseMessage } from "@app/models/responsemessage.model";
import { CreateHolidayModel } from "@app/models/holiday/createholiday.model";
import { EditHolidayModel } from "@app/models/holiday/editholiday.model";

const config = new Config();

@Injectable()
export class HolidayService {
  readonly rootUrl = config.webAPIUrl + '/api/holiday';

  constructor(private jsonApiService: JsonApiService, private http: HttpClient) {
  }

  delete(holidayId: number): Observable<object> {
    return this.http.get<IResponseMessage>(this.rootUrl + '/delete/' + holidayId);
  }

  create(holiday: CreateHolidayModel): Observable<object> {
    return this.http.post(this.rootUrl + '/create', holiday, {});
  }

  get(holidayId: number): Observable<IResponseMessage> {
    return this.http.get<IResponseMessage>(this.rootUrl + '/get/' + holidayId);
  }

  edit(holiday: EditHolidayModel): Observable<object> {
    return this.http.post(this.rootUrl + '/edit', holiday, {});
  }

  getOncomingHolidayList(year: string, timeZone: string ): Observable<object> {
    return this.http.get(this.rootUrl+"/list/oncomingholiday?holidayYear="+year+"&timeZone="+timeZone);
}
}
