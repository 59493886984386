import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { MainLayoutComponent } from "./shared/layout/app-layouts/main-layout.component";
import { AuthLayoutComponent } from "./shared/layout/app-layouts/auth-layout.component";
import { AuthGuard } from '@app/core/guards/auth.guard';

const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    canActivate:[AuthGuard],
    data: { pageTitle: "Home" },
    children: [     
      {
        path: "",
        redirectTo: "/spread/list",
        pathMatch: "full"
      },
      {
        path: "dashboard",
        loadChildren: "./features/dashboard/dashboard.module#DashboardModule",
        data: { pageTitle: "Mt4Account" }
      },
      {
        path: "spread",
        loadChildren: "./features/spread/spread.module#SpreadModule",
        data: { pageTitle: "Spread" }
      },
      {
        path: "markup",
        loadChildren: "./features/markup/markup.module#MarkupModule",
        data: { pageTitle: "Markup" }
      },
      {
        path: "member",
        loadChildren: "./features/member/member.module#MemberModule",
        data: { pageTitle: "Member" }
      },
      {
        path: "membergroup",
        loadChildren: "./features/membergroup/membergroup.module#MemberGroupModule",
        data: { pageTitle: "Markup" }
      },
      {
        path: "adminuser",
        loadChildren: "./features/adminuser/adminuser.module#AdminUserModule",
        data: { pageTitle: "AdminUser" }
      },
      {
        path: "product",
        loadChildren: "./features/product/product.module#ProductModule",
        data: { pageTitle: "Product" }
      },
      {
        path: "report",
        loadChildren: "./features/report/report.module#ReportModule",
        data: { pageTitle: "Report" }
      },
      {
        path: "profile",
        loadChildren: "./features/profile/profile.module#ProfileModule",
        data: { pageTitle: "Profile" }
      },
      {
        path: "pricebuffer",
        loadChildren: "./features/pricebuffer/pricebuffer.module#PriceBufferModule",
        data: { pageTitle: "Price Buffer" }
      },
      {
        path: "schedule",
        loadChildren: "./features/schedule/schedule.module#ScheduleModule",
        data: { pageTitle: "Schedule" }
      },
      {
        path: "holiday",
        loadChildren: "./features/holiday/holiday.module#HolidayModule",
        data: { pageTitle: "Holiday" }
      },
      {
        path: "bookswitcher",
        loadChildren: "./features/bookswitcher/bookswitcher.module#BookSwitcherModule",
        data: { pageTitle: "Manual Switcher" }
      },
      {
        path: "tradeexecution",
        loadChildren: "./features/tradeexecution/tradeexecution.module#TradeExecutionModule",
        data: { pageTitle: "Manual Switcher" }
      },
      {
        path: "traderesidue",
        loadChildren: "./features/traderesidue/traderesidue.module#TradeResidueModule",
        data: { pageTitle: "Manual Switcher" }
      },
      {
        path: "unauthorized",
        loadChildren: "./features/auth/unauthorized/unauthorized.module#UnauthorizedModule",
        data: { pageTitle: "Unauthorized Access" }
      },
      {
        path: "trade",
        loadChildren: "./features/trade/trade.module#TradeModule",
        data: { pageTitle: "Trade" }
        },
    ]
  },
  {
    path: "auth",
    component: AuthLayoutComponent,
    loadChildren: "./features/auth/auth.module#AuthModule"
  },
  { path: "**", redirectTo: "miscellaneous/error404" }


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
