import {Injectable} from '@angular/core';

declare var $: any;

@Injectable()
export class NotificationService {

  constructor() {
  }

  smallBox(data, cb?) {
    $.smallBox(data, cb)
  }

  bigBox(data, cb?) {
    $.bigBox(data, cb)
  }

  smartMessageBox(data, cb?) {
    $.SmartMessageBox(data, cb)
  }
  
  errorMessage(message)
  {
    $.smallBox(
      {
        title: "Error",
        content: "<b>" + message + "</b>",
        color: "#C46A69",
        icon: "fa fa-times fadeInRight animated",
        timeout: 4000
    }, null)
  }

  successMessage(message)
  {
    $.smallBox({
      title: "Success",
      content: "<b>"+message+"</b>",
      color: "rgb(51, 94, 120)",
      icon: "fa fa-check fadeInRight animated",
      timeout: 4000
    });
  }
}
