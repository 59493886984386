import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from "@angular/http";
import { BehaviorSubject } from "rxjs";
import { JsonApiService } from "@app/core/services/json-api.service";
import { Observable } from "rxjs/Observable";
import { SpreadModel } from "@app/models/spread/spread.model";
import { IResponseMessage } from "@app/models/responsemessage.model";
import { Config } from "@app/config/config";

const config = new Config();
@Injectable()
export class SpreadService {
    readonly rootUrl = config.webAPIUrl + '/api';

    constructor(private jsonApiService: JsonApiService, private http: HttpClient) {
    }

    edit(spreadModel: SpreadModel): Observable<object> {
        return this.http.post(this.rootUrl + '/Spread/edit', spreadModel, {});
    }

    getAll(): Observable<IResponseMessage> {
        return this.http.get<IResponseMessage>(this.rootUrl + '/Spread/get/all');
    }
}
