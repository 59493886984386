import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from "@angular/http";
import { BehaviorSubject } from "rxjs";
import { JsonApiService } from "@app/core/services/json-api.service";
import { IMt4Account } from "@app/models/mt4account.model";
import { Observable } from "rxjs/Observable";
import { EditMt4AccountModel } from '@app/models/editmt4account.model';
import { IResponseMessage } from "@app/models/responsemessage.model";
import { Config } from "@app/config/config";

const config = new Config();

const defaultUser = {
  username: "Admin"
}
@Injectable()
export class Mt4AccountService {
  readonly rootUrl = config.webAPIUrl+'/api';

  public user$ = new BehaviorSubject({...defaultUser});

  constructor(private jsonApiService: JsonApiService, private http: HttpClient) {
    //this.jsonApiService.fetch("/user/login-info.json").subscribe(this.user$)
  }

  get(mt4AccountId: number): Observable<IMt4Account> {
    var data = "mt4AccountId=" + mt4AccountId;
    return this.http.get<IMt4Account>(this.rootUrl + '/Mt4Account/get?'+data);
  }

  edit(mt4Account: EditMt4AccountModel): Observable<object> {
    return this.http.post(this.rootUrl + '/Mt4Account/Edit', mt4Account,{});
  }

  delete(mt4AccountId: number): Observable<object> {
    var data = "mt4AccountId=" + mt4AccountId;
    return this.http.get<IResponseMessage>(this.rootUrl + '/Mt4Account/delete?'+data);
  }

  getDashboardSummary(){
   // return this.http.get(, {},{});
    return this.http.get(this.rootUrl + '/dashboard/summary');
   }

   getSimilarClientName(clientname: string): Observable<object> {
    var data = "clientname=" + clientname;
    return this.http.get<IResponseMessage>(this.rootUrl + '/Mt4Account/getsimilarclientname?'+data);
  }

  createMt4Account(data){
    return this.http.post(this.rootUrl + '/Mt4Account/Create', data,{});
   }
}
