export class Config {
    //PROD
    webAPIUrl = 'https://api.vmbullion.com.sg/'
    signalRUrl = "https://api.vmbullion.com.sg:6008/signalr"

    //UAT 
    // webAPIUrl = 'http://valuemax-api.stoneinno.com/'
    // signalRUrl = 'http://valuemax-api.stoneinno.com:6007/signalr'

    //SIT
     //webAPIUrl = 'http://sit.goldpro.api.stoneinno.com/'
    // signalRUrl = 'http://47.91.231.122:5006/signalr'

    //LOCAL
    // webAPIUrl = 'http://localhost:63670/'
    // signalRUrl = 'http://127.0.0.1:8084/signalr'

    signalRProxyName = 'PricingBroadcasterHub'
    signalRNotifySpotPrice = 'NotifySpotPrice'
    signalRNotifyProductPrice = 'NotifyProductPrice'

    companyName = 'ValueMax'
    accessPortal = 'ValueMax Admin Portal'
}
