import { Component, OnInit } from '@angular/core';
import { Config } from "@app/config/config";
const config = new Config();

@Component({
  selector: 'sa-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  public thisYear=new Date().getFullYear()
  public companyName=config.companyName
  constructor() {}

  ngOnInit() {}

}
