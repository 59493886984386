import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from "@angular/http";
import { BehaviorSubject } from "rxjs";
import { JsonApiService } from "@app/core/services/json-api.service";
import { map } from 'rxjs/operators';
import { Config } from "@app/config/config";
import { MarketPriceModel } from "@app/models/marketprice.model";
import { ProductMarkupListModel } from "@app/models/product/productmarkuplist.model";

declare var jquery:any;
declare var $ :any;
const config = new Config(); 
@Injectable()
export class SignalRService {
    readonly rootUrl = config.webAPIUrl;
    readonly signalRUrl = config.signalRUrl;
    readonly signalRProxyName = config.signalRProxyName;
    readonly signalRNotifySpotPrice = config.signalRNotifySpotPrice;
    readonly signalRNotifyProductPrice = config.signalRNotifyProductPrice;

    private proxy: any;  
    private connection: any;  
    // create the Event Emitter  
    public marketPrice: EventEmitter < MarketPriceModel > ;  
    public product: EventEmitter < ProductMarkupListModel > ; 
    public connectionEstablished: EventEmitter < Boolean > ;  
    public connectionExists: Boolean;  
    constructor() {   
        // Constructor initialization  
        this.connectionEstablished = new EventEmitter < Boolean > ();  
        this.marketPrice = new EventEmitter < MarketPriceModel > ();  
        this.product = new EventEmitter < ProductMarkupListModel > ();  
        this.connectionExists = false;  
        // create hub connection  
        this.connection = $.hubConnection(this.signalRUrl);  

        // create new proxy as name already given in top  
        this.proxy = this.connection.createHubProxy(this.signalRProxyName);  
        // register on server events  
        this.registerOnServerEvents();  
        this.registerProductOnServerEvents();  
        // call the connecion start method to start the connection to send and receive events.  
        this.startConnection();  

       
    }  

    // check in the browser console for either signalr connected or not  
    private startConnection(): void {  
        this.connection.start().done((data: any) => {  
            console.log('Now connected ' + data.transport.name + ', connection ID= ' + data.id);  
            this.connectionEstablished.emit(true);  
            this.connectionExists = true;  
        }).fail((error: any) => {  
            console.log('Could not connect ' + error);  
            this.connectionEstablished.emit(false);  
        });  
    }  
    private registerOnServerEvents(): void {  
        this.proxy.on(this.signalRNotifySpotPrice, (data: any) => {  
            //console.log(data);  
            this.marketPrice.emit(data);  
        });  
    }  

    private registerProductOnServerEvents(): void {  
        this.proxy.on(this.signalRNotifyProductPrice, (data: any) => {  
            //console.log(data);  
            this.product.emit(data);  
        });  
    }  
}  