import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from "@angular/http";
import { BehaviorSubject } from "rxjs";
import { JsonApiService } from "@app/core/services/json-api.service";
import { Observable } from "rxjs/Observable";
import { CreateAdminUserModel } from "@app/models/adminuser/createadminuser.model";
import { IResponseMessage } from "@app/models/responsemessage.model";
import { IAdminUser } from "@app/models/adminuser/adminuser.model";
import { EditAdminUserModel } from "@app/models/adminuser/editadminuser.model";
import { Config } from "@app/config/config";
import { ChangePasswordModel } from "@app/models/changepassword.model";
import { AdminUserResetPasswordModel } from '@app/models/adminuser/adminuserresetpassword.model';

const defaultUser = {
    username: "Admin"
}

const config = new Config();
@Injectable()
export class AdminUserService {
    readonly rootUrl = config.webAPIUrl + '/api';

    public user$ = new BehaviorSubject({ ...defaultUser });

    constructor(private jsonApiService: JsonApiService, private http: HttpClient) {
        //this.jsonApiService.fetch("/user/login-info.json").subscribe(this.user$)
    }

    get(adminUserId: number): Observable<IResponseMessage> {
        return this.http.get<IResponseMessage>(this.rootUrl + '/admin/get/' + adminUserId);
    }

    getAdminUserList() {
        return this.http.get(this.rootUrl + '/admin/getcustomlisting');
    }

    enable(userId: number): Observable<object> {
        return this.http.get<IResponseMessage>(this.rootUrl + '/admin/enable/' + userId);
    }

    disable(userId: number): Observable<object> {
        return this.http.get<IResponseMessage>(this.rootUrl + '/admin/disable/' + userId);
    }

    delete(userId: number): Observable<object> {
        var data = "userId=" + userId;
        return this.http.get<IResponseMessage>(this.rootUrl + '/admin/delete?' + data);
    }

    create(adminUser: CreateAdminUserModel): Observable<object> {
        return this.http.post(this.rootUrl + '/admin/create', adminUser, {});
    }

    edit(adminUser: EditAdminUserModel): Observable<object> {
        return this.http.post(this.rootUrl + '/admin/edit', adminUser, {});
    }

    validateEmail(email: string): Observable<object> {
        var data = "email=" + email;
        return this.http.get<IResponseMessage>(this.rootUrl + '/admin/validateemail?' + data);
    }

    changeAdminUserpassword(model: AdminUserResetPasswordModel): Observable<object> {
        return this.http.post(this.rootUrl + '/admin/changeadminuserpassword', model, {});
    }
}
