import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NotificationService } from '@app/core/services';
import { MemberGroupService } from '@app/core/services/membergroup.service';
import { ModalDirective } from "ngx-bootstrap";;
import { DatatableComponent } from '@app/shared/ui/datatable/datatable.component';
import { MemberGroups } from '@app/models/membergroup/membergroup.model';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MemberDetailsModel } from '@app/models/member/memberdetails.model';
import { MemberService } from '@app/core/services/member.service';
import { CountryService } from '@app/core/services/country.service';
import { Country } from '@app/core/model/country.model';

@Component({
    selector: 'member-view',
    templateUrl: './view.component.html',
})

export class MemberViewComponent implements OnInit {
    public loading = false;
    memberGroups: MemberGroups[];
    countries: Country[];

    submitted = false;

    public model = new MemberDetailsModel();

    constructor(private notificationService: NotificationService, private route: ActivatedRoute,
        private memberGroupService: MemberGroupService, private datePipe: DatePipe,
        private formBuilder: FormBuilder, private countryService: CountryService,
        private memberService: MemberService) { };

    _memberId: number;
    _modal: any;
    _memberList: any;

    ngOnInit() {
    }

    onSubmit() {
        this.save();
    }

    ngAfterViewInit() {
    }

    initialize(memberId: number, modal: ModalDirective, memberList: DatatableComponent) {
        this.loading = true;
        this._modal = modal;
        this._memberList = memberList;
        this._memberId = memberId;
        this.memberService.get(this._memberId).subscribe(
            data => {
                console.log(data);
                if (data.Success) {
                    this.loading = false;
                    //initialize model
                    this.model.MemberId = data.Data.Id;
                    this.model.Name = data.Data.Name;
                    this.model.Email = data.Data.Email;
                    this.model.ReferenceNumber = data.Data.ReferenceNumber;
                    this.model.ContactPerson = data.Data.ContactPerson;
                    this.model.ContactPersonDesignation = data.Data.ContactPersonDesignation;
                    this.model.ContactNumber = data.Data.ContactNumber;
                    this.model.MemberGroupId = data.Data.MemberGroupId;
                    this.model.MemberGroup = data.Data.MemberGroup;
                    this.model.MemberStatusId = data.Data.MemberStatusId;
                    this.model.DepositReceived = data.Data.DepositReceived;
                    this.model.UserId = data.Data.UserId;
                    this.model.BuyLimit = data.Data.BuyLimit;
                    this.model.SellLimit = data.Data.SellLimit;

                    this.model.AddressId = data.Data.AddressId;
                    this.model.Address1 = data.Data.Address1;
                    this.model.Address2 = data.Data.Address2;
                    this.model.City = data.Data.City;
                    this.model.State = data.Data.State;
                    this.model.PostalCode = data.Data.PostalCode;
                    this.model.CountryId = data.Data.CountryId;
                    this.model.Country = data.Data.Country;

                    if (data.Data.DepositReceived != null && data.Data.DepositReceived > 0) {
                        this.model.IsDepositRequired = true;
                        $("#depositreceivedEdit").removeAttr("disabled");
                        $("#depositreceivedEdit").prop('required', true);
                        $("#depositreceivedrequiredEdit").show();
                    }
                    else {
                        this.model.IsDepositRequired = false;
                        $("#depositreceivedEdit").val(null);
                        $("#depositreceivedEdit").prop("disabled", "disabled");
                        $("#depositreceivedEdit").prop('required', false);
                        $(".depositreceivedrequiredEdit").hide();
                    }

                    if ((data.Data.BuyLimit != null && data.Data.BuyLimit > 0) ||
                        data.Data.SellLimit != null && data.Data.SellLimit > 0) {
                        this.model.OverrideBuySellLimit = true;
                        $("#buylimitEdit").removeAttr("disabled");
                        $("#buylimitEdit").prop('required', true);
                        $("#selllimitEdit").removeAttr("disabled");
                        $("#selllimitEdit").prop('required', true);
                    }
                    else {
                        this.model.OverrideBuySellLimit = false;
                        $("#buylimitEdit").val(null);
                        $("#buylimitEdit").prop("disabled", "disabled");
                        $("#buylimitEdit").prop('required', false);

                        this.model.SellLimit = null;
                        $("#selllimitEdit").val(null);
                        $("#selllimitEdit").prop("disabled", "disabled");
                        $("#selllimitEdit").prop('required', false);
                    }
                }

            },
            err => console.error(err)
        );
    }

    memberGroupOnChange(event: any) {
        if ($("#dropdownMemberGroupEdit option:selected").length > 0) {
            let selectedMemberGroup = $("#dropdownMemberGroupEdit option:selected")[0];
            let selectedMemberGroupId = selectedMemberGroup.getAttribute("ng-reflect-ng-value");
            let memberGroup = this.memberGroups.find(x => x.Id == selectedMemberGroupId);

            if (memberGroup.DepositRequired) {
                this.model.IsDepositRequired = true;
                $("#depositreceivedEdit").removeAttr("disabled");
                $("#depositreceivedEdit").prop('required', true);
                $(".depositreceivedrequiredEdit").show();
            }
            else {
                this.model.IsDepositRequired = false;
                this.model.DepositReceived = null;
                $("#depositreceivedEdit").val(null);
                $("#depositreceivedEdit").prop("disabled", "disabled");
                $("#depositreceivedEdit").prop('required', false);
                $(".depositreceivedrequiredEdit").hide();
            }
        }
    }

    overrideBuySellLimitChange(values: any) {
        if (this.model.OverrideBuySellLimit) {
            $("#buylimitEdit").removeAttr("disabled");
            $("#buylimitEdit").prop('required', true);
            $("#selllimitEdit").removeAttr("disabled");
            $("#selllimitEdit").prop('required', true);
        }
        else {
            this.model.BuyLimit = null;
            $("#buylimitEdit").val(null);
            $("#buylimitEdit").prop("disabled", "disabled");
            $("#buylimitEdit").prop('required', false);

            this.model.SellLimit = null;
            $("#selllimitEdit").val(null);
            $("#selllimitEdit").prop("disabled", "disabled");
            $("#selllimitEdit").prop('required', false);
        }
    }

    save() {
        this.loading = true;
        this.memberService.edit(this.model)
            .subscribe((data: any) => {
                this.loading = false;
                this.loading = false;
                if (data.Success) {
                    this.notificationService.successMessage("Member edited successfully.")
                    this._memberList.refreshTable();
                    this._modal.hide();
                }
                else {
                    this.notificationService.errorMessage(data.Message)
                }
            });
    }
}