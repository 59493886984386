import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from "@angular/http";
import { BehaviorSubject } from "rxjs";
import { JsonApiService } from "@app/core/services/json-api.service";
import { map } from 'rxjs/operators';
import { Config } from "@app/config/config";

const config = new Config();

@Injectable()
export class EaService {
    readonly rootUrl = config.webAPIUrl+"/api";

    constructor(private jsonApiService: JsonApiService, private http: HttpClient) {
    }
  
     getEas() {
         return this.http.get(this.rootUrl+"/Ea/List");
     }

     getEasByGroup() {
        return this.http.get(this.rootUrl+"/Ea/List/Group");
    }
  }
  