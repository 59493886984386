import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from "@angular/http";
import { BehaviorSubject } from "rxjs";
import { JsonApiService } from "@app/core/services/json-api.service";
import { Observable } from "rxjs/Observable";
import { PriceBufferModel } from "@app/models/pricebuffer.model";
import { IResponseMessage } from "@app/models/responsemessage.model";
import { Config } from "@app/config/config";

const config = new Config();
@Injectable()
export class PriceBufferService {
    readonly rootUrl = config.webAPIUrl + '/api';

    constructor(private jsonApiService: JsonApiService, private http: HttpClient) {
    }

    edit(priceBufferModel: PriceBufferModel): Observable<object> {
        return this.http.post(this.rootUrl + '/pricebuffer/edit', priceBufferModel, {});
    }

    getAll(): Observable<IResponseMessage> {
        return this.http.get<IResponseMessage>(this.rootUrl + '/pricebuffer/get/all');
    }
}
